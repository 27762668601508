import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import styles from './IntegrationsListHeader.module.css';

import Container from '../../../../blocks/Container';
import FullWidthRow from '../../../../blocks/FullWidthRow';
import Title from '../../../../elements/Title';
import Text from '../../../../elements/Text';
import Image from '../../../../elements/Image';

const IntegrationsListHeader = ({ search, onChangeSearchString }) => (
  <div className={styles.integrationsListHeader}>
    <Container type="big" className={`${styles.integrationsListHeader__container} h-100`}>
      <FullWidthRow>
        <Container type="mobile">
          <Title className={`${styles.integrationsListHeader__title} white-text`}>
            Deadline Funnel Integrations
          </Title>
          <div className="d-flex justify-content-center">
            <Text className={`${styles.integrationsListHeader__description} white-text text-center`}>
              All your favorite landing page and email platforms, integrated with Deadline Funnel.
            </Text>
          </div>
        </Container>
        <div className={styles.integrationsListHeader__actionsImagesContainer}>
          <div className={styles.integrationsListHeader__searchInputContainer}>
            <input
              type="text"
              className={styles.integrationsListHeader__searchInput}
              onChange={e => onChangeSearchString(e.target.value)}
              value={search}
              placeholder="search integrations"
            />
            <FontAwesomeIcon
              icon={faSearch}
              className={styles.integrationsListHeader__searchInputIcon}
              style={{ maxWidth: '21px', maxHeight: '21px' }}
            />
            <Image name="guy_left" className={styles.integrationsListHeader__guyLeft} />
            <Image name="girl_right" className={styles.integrationsListHeader__girlRight} />
            <Image name="girl_center" className={styles.integrationsListHeader__girlCenter} />
            <Image name="rock_left" className={styles.integrationsListHeader__rockLeft} />
            <Image name="rock_right" className={styles.integrationsListHeader__rockRight} />
          </div>
        </div>
      </FullWidthRow>
      <Image name="plant_left" className={styles.integrationsListHeader__plantLeft} />
      <Image name="plant_right" className={styles.integrationsListHeader__plantRight} />
    </Container>
  </div>
);

IntegrationsListHeader.propTypes = {
  search: PropTypes.string.isRequired,
  onChangeSearchString: PropTypes.func.isRequired
};

export default IntegrationsListHeader;