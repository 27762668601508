import React from 'react';
import PropTypes from 'prop-types';

import styles from './OptionsList.module.css';

const OptionsList = ({ options, selectedOption, onChangeOption, className, title }) => {
  return (
    <div className={`${styles.optionsList} ${className}`}>
      <div className={`${styles.optionsList__title}`}>{title}:</div>
      {
        options.map(option => (
          <div
            key={option.title}
            className={`${selectedOption.title === option.title ? styles.optionsList__selected : ''} ${styles.optionsList__option}`}
            onClick={() => onChangeOption(option)}
            onKeyDown={() => onChangeOption(option)}
            role="button"
            tabIndex={0}
          >
            {option.title}
          </div>
        ))
      }
    </div>
  )
};

OptionsList.defaultProps = {
  className: ''
};

OptionsList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string
  })).isRequired,
  selectedOption: PropTypes.shape({
    title: PropTypes.string.isRequired,
    value: PropTypes.string
  }).isRequired,
  onChangeOption: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default OptionsList;