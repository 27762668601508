import React, { Fragment, useState } from 'react';

import IntegrationsListHeader from './IntegrationsListHeader';
import IntegrationsListContent from './IntegrationsListContent';

const IntegrationsList = () => {
  const [search, setSearch] = useState('');

  return (
    <Fragment>
      <IntegrationsListHeader search={search} onChangeSearchString={setSearch} />
      <IntegrationsListContent search={search} />
    </Fragment>
  );
};

export default IntegrationsList;