import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from './MobileOptionsMenu.module.css';

import Container from '../../../../../blocks/Container';

const MobileOptionsMenu = ({ sortOptions, filterOptions }) => {

  const [thisOpened, setThisOpened] = useState(false);

  const openThis = () => {
    if (thisOpened) return;
    setThisOpened(true);
  };

  const closeThis = () => {
    if (!thisOpened) return;
    setThisOpened(false);
  };

  return (
    <Container type="mobile" className="w-100">
      <div className={`${styles.mobileOptionsMenu} ${thisOpened ? styles.mobileOptionsMenuOpened : ''}`}>
        <div className={styles.mobileOptionsMenu__title} onClick={openThis} onKeyDown={openThis} role="button" tabIndex={0}>
          Filter
          <div className={styles.mobileOptionsMenu__close} onClick={closeThis} onKeyDown={closeThis} role="button" tabIndex={0}>
            &times;
          </div>
        </div>
        <hr className={styles.mobileOptionsMenu__hr} />
        <div className={styles.mobileOptionsMenu__optionsContainer}>
          {sortOptions}
        </div>
        <hr className={styles.mobileOptionsMenu__hr} />
        <div className={styles.mobileOptionsMenu__optionsContainer}>
          {filterOptions}
        </div>
      </div>
    </Container>
  )
};

MobileOptionsMenu.propTypes = {
  sortOptions: PropTypes.node.isRequired,
  filterOptions: PropTypes.node.isRequired
};

export default MobileOptionsMenu;