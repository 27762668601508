import React from 'react';

import Layout from '../components/blocks/Layout';
import SEO from '../components/blocks/SEO';
import IntegrationsList from '../components/slides/integrations/IntegrationsList';

const Integrations = () => {
  return (
    <Layout>
      <SEO
        title="Integrations | Deadline Funnel"
        description="All your favorite landing page and email platforms, integrated with Deadline Funnel."
      />
      <IntegrationsList />
    </Layout>
  );
};

export default Integrations;
