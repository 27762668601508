import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useStaticQuery, graphql } from 'gatsby';

import styles from './IntegrationsListContent.module.css';

import Container from '../../../../blocks/Container';
import Image from '../../../../elements/Image';
import OptionsList from './OptionsList';
import MobileOptionsMenu from './MobileOptionsMenu';

const IntegrationsListContent = ({ search }) => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/integrations/" } }) {
        edges {
          node {
            frontmatter {
              integrationsList {
                title
                name
                image
                popularity
                category
              }
              sortOptions {
                title
                sortField
              }
              filterOptions {
                title
              }
            }
          }
        }
      }
    }
  `);

  const { integrationsList } = data.allMarkdownRemark.edges.find(({ node }) => node.frontmatter.integrationsList).node.frontmatter;
  const { sortOptions } = data.allMarkdownRemark.edges.find(({ node }) => node.frontmatter.sortOptions).node.frontmatter;
  const { filterOptions } = data.allMarkdownRemark.edges.find(({ node }) => node.frontmatter.filterOptions).node.frontmatter;

  const [selectedSortOption, setSelectedSortOption] = useState(sortOptions[0]);
  const [selectedFilterOption, setSelectedFilterOption] = useState(filterOptions[0]);

  const sort = (first, second) => {
    const firstSortedValue = +first[selectedSortOption.sortField] || first[selectedSortOption.sortField];
    const secondSortedValue = +second[selectedSortOption.sortField] || second[selectedSortOption.sortField];
    if (firstSortedValue > secondSortedValue) {
      return 1;
    }
    if (firstSortedValue < secondSortedValue) {
      return -1;
    }
    return 0;
  };

  const filter = integration => {
    const searchCondition = search ? integration.title.toLowerCase().includes(search.toLowerCase()) : true;

    return selectedFilterOption.title !== 'All integrations'
      ? integration.category === selectedFilterOption.title && searchCondition
      : searchCondition;
  };

  const integrations = integrationsList.sort(sort).filter(filter);

  return (
    <div className={styles.integrationsListContent}>
      <Container type="big">
        <div className={`${styles.integrationsListContent__mobileMenuContainer} row`}>
          <div className="d-xl-none d-lg-none d-md-none col-12 d-flex justify-content-center">
            <MobileOptionsMenu
              sortOptions={
                <OptionsList
                  title="Sort by"
                  options={sortOptions}
                  selectedOption={selectedSortOption}
                  onChangeOption={setSelectedSortOption}
                />
              }
              filterOptions={
                <OptionsList
                  title="Filter by"
                  options={filterOptions}
                  selectedOption={selectedFilterOption}
                  onChangeOption={setSelectedFilterOption}
                />
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-md-4 d-xl-block d-lg-block d-md-block d-none pr-xl-0">
            <OptionsList
              title="Sort by"
              options={sortOptions}
              selectedOption={selectedSortOption}
              onChangeOption={setSelectedSortOption}
            />
            <OptionsList
              className="mt-5"
              title="Filter by"
              options={filterOptions}
              selectedOption={selectedFilterOption}
              onChangeOption={setSelectedFilterOption}
            />
          </div>
          <div className="col-xl-10 col-lg-9 col-md-8 col-12">
            <div className={styles.integrationItemsContainer}>
              {
                integrations.map(integration => (
                  <a key={integration.image} className={styles.integrationItem} href={integration.name}>
                    <Image name={integration.image} className={styles.integrationItem__image} />
                    <div className={styles.integrationItem__title}>{integration.title}</div>
                  </a>
                ))
              }
              {
                !integrations.length &&
                <div className={styles.integrationsListContent__noResults}>
                  No results found
                </div>
              }
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
};

IntegrationsListContent.defaultProps = {
  search: ''
};

IntegrationsListContent.propTypes = {
  search: PropTypes.string
};

export default IntegrationsListContent;